<template>
  <div class="chapter">
    <div class="chapter-wrapper">
      <div class="zlxc-container" style="overflow: visible">
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>题库练习</el-breadcrumb-item>
            <el-breadcrumb-item>章节练习</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="chapter-choice">
          <div class="choice-title">{{stepType == 1 ? '科目一' : '科目四'}} 章节练习</div>
          <div class="choice-list" v-if="chapterDataList && chapterDataList.length > 0">
            <div class="choice-li" v-for="(item,index) in chapterDataList" :key="item.id">
              <p class="li-title"><span class="section">第{{index+1}}章</span>{{item.title}} （{{item.count}}）</p>
              <ul class="li-question">
                <li class="question" @click="tapSequencePractise(item.id)">
                  <p class="que-circle"></p>
                  <p class="que-literal">顺序答题</p>
                </li>
                <li class="question" @click="tapRandomPractise(item.id)">
                  <p class="que-circle"></p>
                  <p class="que-literal">随机答题</p>
                </li>
              </ul>
            </div>
          </div>
          <el-empty :image-size="200" v-else></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryGetChapterList } from '@/api/herbalMedicine'

export default {
  name: 'ChapterChoice',
  data () {
    return {
      // 车型
      carType: 'car',
      // 科目
      stepType: 1,
      // 章节练习列表
      chapterDataList: null,
    }
  },
  computed: {
  },
  created () {
    this.carType = this.$route.query.carType == 'trolley' ? 'car' : this.$route.query.carType
    this.stepType = this.$route.query.stepType == 'branchOne' ? 1 : 4
    this.getQueryGetChapterList()
  },
  methods: {
    // 获取章节目录
    getQueryGetChapterList () {
      queryGetChapterList({
        carType: this.carType,
        stepType: this.stepType
      }).then(res => {
        if (res.data.length > 0) {
          this.chapterDataList = res.data
        }
      })
    },
    // 触发顺序答题
    tapSequencePractise (page) {
      console.log(page)
      this.$router.push({
        name:'Practise',
        query: {
          carType: this.carType,
          stepType: this.stepType == 1 ? 'branchOne' : 'branchFour',
          isOrder: 'order',
          exerciseType: 'chapter',
          chapter: page
        }
      })
    },
    // 触发随机答题
    tapRandomPractise (page) {
      this.$router.push({
        name:'Practise',
        query: {
          carType: this.carType,
          stepType: this.stepType == 1 ? 'branchOne' : 'branchFour',
          isOrder: 'noorder',
          exerciseType: 'chapter',
          chapter: page
        }
      })
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
